import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { DataStore } from '@aws-amplify/datastore';
import { Media as MediaModel, Campaign as CampaignModel } from '../models';
import MediaContent from './MediaContent'; // Usando o MediaContent
import styles from './HorizontalMedia.module.css'; // Usando CSS Modules
import { WiNa, WiDaySunny } from 'react-icons/wi';
import { FaCircle } from 'react-icons/fa';

function HorizontalMedia({ transitionTime, theme, zoomEffect, slideEffect }) {
  const { campaignId } = useParams();
  const [mediaItems, setMediaItems] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);
  const timerRef = useRef(null);
  const [currentTime, setCurrentTime] = useState(new Date());
  const [onlineStatus, setOnlineStatus] = useState(navigator.onLine);
  const [weatherData, setWeatherData] = useState(null);
  const [locate, setLocate] = useState('');

  // Atualiza data e hora a cada minuto
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  // Checa status de conexão
  useEffect(() => {
    const handleOnline = () => setOnlineStatus(true);
    const handleOffline = () => setOnlineStatus(false);
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);
    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  // Busca informações de clima com base no nome da localização
  const fetchWeatherData = async (locate) => {
    if (!locate || locate === 'Mídia Geral') {
      setWeatherData(null);
      return;
    }
    try {
      const response = await fetch(
        `https://api.openweathermap.org/data/2.5/weather?q=${locate},br&appid=131c252944882a0d87ea5fa95ec07cf8&units=metric`
      );
      const data = await response.json();
      if (data && data.main) {
        setWeatherData(data);
      } else {
        setWeatherData(null);
      }
    } catch (error) {
      console.error('Erro ao buscar dados do clima:', error);
      setWeatherData(null);
    }
  };

  useEffect(() => {
    fetchWeatherData(locate);
  }, [locate]);

  // Busca as mídias e a localização da campanha
  useEffect(() => {
    const fetchData = async () => {
      try {
        const mediaData = await DataStore.query(MediaModel, (c) =>
          c.campaignId.eq(campaignId)
        );
        setMediaItems(mediaData);

        const campaignData = await DataStore.query(CampaignModel, campaignId);
        const campaignLocate = campaignData?.locate || 'Mídia Geral';
        setLocate(campaignLocate);
      } catch (error) {
        console.error('Erro ao buscar dados:', error);
        setLocate('Mídia Geral');
      }
    };

    fetchData();
  }, [campaignId]);

  // Troca de slide e reinicia o temporizador circular a cada mídia
  useEffect(() => {
    if (mediaItems.length === 0) return;

    timerRef.current = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % mediaItems.length);
    }, transitionTime * 1000);

    return () => clearInterval(timerRef.current);
  }, [mediaItems.length, transitionTime]);

  const getLayout = (media) => {
    const { content } = media;

    if (
      content &&
      media.mediaKey &&
      /\.(jpg|jpeg|png|gif|mp4|mov|webm|ogg)$/i.test(media.mediaKey)
    ) {
      return (
        <div className={styles.mediaWithContent}>
          <div className={styles.mediaSide}>
            <MediaContent media={media} zoomEffect={zoomEffect} theme={theme} />
          </div>
          <div className={styles.contentSide}>
            <div
              className={`${styles.contentBox} ${
                theme === 'dark' ? styles.lightBox : styles.darkBox
              }`}
            >
              <p>{content}</p>
            </div>
          </div>
        </div>
      );
    } else {
      return <MediaContent media={media} zoomEffect={zoomEffect} theme={theme} />;
    }
  };

  const formatTemperature = (temp) => {
    return temp.toFixed(2);
  };

  return (
    <div className={`${styles.horizontalMedia} ${theme}`}>
      {/* Barra de status fixa no rodapé */}
      <div className={styles.statusBar}>
        <div className={styles.statusLeft}>
          <span>
            {currentTime.toLocaleDateString()}{' '}
            {currentTime.toLocaleTimeString([], {
              hour: '2-digit',
              minute: '2-digit',
            })}
          </span>
          <span style={{ paddingLeft: '10px' }}>
            {locate || 'Mídia Geral'}
          </span>
          {weatherData?.weather && weatherData.weather[0] ? (
            <WiDaySunny
              size={24}
              className={styles.weatherIcon}
              style={{ paddingLeft: '10px' }}
            />
          ) : (
            <WiNa
              size={24}
              className={styles.weatherIcon}
              style={{ paddingLeft: '10px' }}
            />
          )}
          <span className={styles.temperature} style={{ paddingLeft: '10px' }}>
            {weatherData?.main?.temp
              ? `${formatTemperature(weatherData.main.temp)}°C`
              : '25°C'}
          </span>
        </div>
        <div className={styles.statusRight}>
          {onlineStatus ? (
            <FaCircle size={12} color="green" style={{ marginRight: '5px' }} />
          ) : (
            <FaCircle size={12} color="red" style={{ marginRight: '5px' }} />
          )}
          <span>{onlineStatus ? 'Online' : 'Offline'}</span>
          <div className={styles.progressCircle}>
            <svg>
              <circle cx="18" cy="18" r="15"></circle>
              <circle
                cx="18"
                cy="18"
                r="15"
                key={currentSlide}
                style={{
                  animationDuration: `${transitionTime}s`,
                  animationIterationCount: 1,
                }}
              ></circle>
            </svg>
          </div>
        </div>
      </div>

      {/* Container da mídia com slides */}
      <div className={styles.mediaContainer}>
        {mediaItems.map((media, index) => (
          <div
            key={media.id}
            className={`${styles.slide} ${
              index === currentSlide ? styles.active : ''
            } ${index === currentSlide ? slideEffect : ''}`}
          >
            {getLayout(media)}
          </div>
        ))}
      </div>
    </div>
  );
}

export default HorizontalMedia;
