// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { Payment, CentralWallet, CentralSummary, Display, Purchase, Procurement, Transactions, Advertising, Media, Campaign, ClosedSale, Cart, Favorite, Product, Watch, Market, Topic, Community, ChatRoom, Message, Highlight, Follow, Saved, Comment, Click, Eko, Story, Post, Strand, Wallet, User, LastMessage } = initSchema(schema);

export {
  Payment,
  CentralWallet,
  CentralSummary,
  Display,
  Purchase,
  Procurement,
  Transactions,
  Advertising,
  Media,
  Campaign,
  ClosedSale,
  Cart,
  Favorite,
  Product,
  Watch,
  Market,
  Topic,
  Community,
  ChatRoom,
  Message,
  Highlight,
  Follow,
  Saved,
  Comment,
  Click,
  Eko,
  Story,
  Post,
  Strand,
  Wallet,
  User,
  LastMessage
};