// VerticalMedia.jsx
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DataStore } from '@aws-amplify/datastore';
import { Media as MediaModel, Campaign as CampaignModel } from '../models';
import MediaContent from './MediaContent';
import styles from './VerticalMedia.module.css';
import { WiNa, WiDaySunny } from 'react-icons/wi';
import { FaCircle } from 'react-icons/fa';

function VerticalMedia({ transitionTime, theme, zoomEffect, slideEffect }) {
  const { campaignId } = useParams();
  const [mediaItems, setMediaItems] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [currentTime, setCurrentTime] = useState(new Date());
  const [onlineStatus, setOnlineStatus] = useState(navigator.onLine);
  const [weatherData, setWeatherData] = useState(null);
  const [locate, setLocate] = useState('');

  // Atualiza o horário atual a cada minuto
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  // Atualiza o status online/offline
  useEffect(() => {
    const handleOnline = () => setOnlineStatus(true);
    const handleOffline = () => setOnlineStatus(false);
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);
    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  // Busca dados do clima
  const fetchWeatherData = async (locate) => {
    if (!locate || locate === 'Mídia Geral') {
      setWeatherData(null);
      return;
    }
    try {
      const response = await fetch(
        `https://api.openweathermap.org/data/2.5/weather?q=${locate},br&appid=131c252944882a0d87ea5fa95ec07cf8&units=metric`
      );
      const data = await response.json();
      if (data && data.main) {
        setWeatherData(data);
      } else {
        setWeatherData(null);
      }
    } catch (error) {
      console.error('Erro ao buscar dados do clima:', error);
      setWeatherData(null);
    }
  };

  useEffect(() => {
    fetchWeatherData(locate);
  }, [locate]);

  // Busca dados da mídia e campanha
  useEffect(() => {
    const fetchData = async () => {
      try {
        const mediaData = await DataStore.query(MediaModel, (c) =>
          c.campaignId.eq(campaignId)
        );
        setMediaItems(mediaData);

        const campaignData = await DataStore.query(CampaignModel, campaignId);
        const campaignLocate = campaignData?.locate || 'Mídia Geral';
        setLocate(campaignLocate);
      } catch (error) {
        console.error('Erro ao buscar dados:', error);
        setLocate('Mídia Geral');
      }
    };

    fetchData();
  }, [campaignId]);

  // Gerencia a troca de slides
  useEffect(() => {
    if (mediaItems.length === 0) return;

    const timer = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % mediaItems.length);
    }, transitionTime * 1000);

    return () => clearInterval(timer);
  }, [mediaItems.length, transitionTime]);

  const formatTemperature = (temp) => {
    return temp.toFixed(2);
  };

  return (
    <div className={`${styles.verticalMedia} ${theme}`}>
      {/* Barra de status rotacionada */}
      <div className={styles.rotatedStatusBar}>
        <div className={styles.statusLeft}>
          <span>
            {currentTime.toLocaleDateString()}{' '}
            {currentTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
          </span>
          <span>{locate || 'Mídia Geral'}</span>
          {weatherData?.weather && weatherData.weather[0] ? (
            <WiDaySunny size={24} className={styles.weatherIcon} />
          ) : (
            <WiNa size={24} className={styles.weatherIcon} />
          )}
          <span className={styles.temperature}>
            {weatherData?.main?.temp
              ? `${formatTemperature(weatherData.main.temp)}°C`
              : '25°C'}
          </span>
        </div>
        <div className={styles.statusRight}>
          {onlineStatus ? (
            <FaCircle size={12} color="green" />
          ) : (
            <FaCircle size={12} color="red" />
          )}
          <span>{onlineStatus ? 'Online' : 'Offline'}</span>
          <div className={styles.progressCircle}>
            <svg>
              <circle cx="18" cy="18" r="15"></circle>
              <circle
                cx="18"
                cy="18"
                r="15"
                key={currentSlide}
                style={{
                  animationDuration: `${transitionTime}s`,
                  animationIterationCount: 1,
                }}
              ></circle>
            </svg>
          </div>
        </div>
      </div>

      {/* Container de mídia */}
      <div className={styles.mediaContainer}>
        {mediaItems.map((media, index) => (
          <div
            key={media.id}
            className={`${styles.slide} ${index === currentSlide ? styles.active : ''} ${
              index === currentSlide ? slideEffect : ''
            }`}
          >
            <MediaContent media={media} zoomEffect={zoomEffect} theme={theme} />
          </div>
        ))}
      </div>

      {/* Barra de status original (sem conteúdo) */}
      <div className={styles.statusBar}></div>
    </div>
  );
}

export default VerticalMedia;
