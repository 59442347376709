import React from 'react';
import { StorageImage } from '@aws-amplify/ui-react-storage';
import PropTypes from 'prop-types';
import styles from './MediaContent.module.css'; // CSS para MediaContent

function MediaContent({ media, zoomEffect, theme }) {
  const { mediaKey, content } = media;
  const isImage = mediaKey && /\.(jpg|jpeg|png|gif)$/i.test(mediaKey);
  const isVideo = mediaKey && /\.(mp4|mov|webm|ogg)$/i.test(mediaKey);

  // Renderiza imagem ou vídeo
  if (isImage) {
    return (
      <div className={styles.mediaOnly}>
        <div className={`${styles.mediaContainer} ${zoomEffect}`}>
          <StorageImage
            imgKey={mediaKey}
            accessLevel="guest"
            className={styles.mediaElement}
          />
        </div>
      </div>
    );
  }

  if (isVideo) {
    return (
      <div className={styles.mediaOnly}>
        <div className={`${styles.mediaContainer} ${zoomEffect}`}>
          <video className={styles.mediaElement} autoPlay loop muted>
            <source
              src={`https://your-amplify-storage-url/${mediaKey}`}
              type={`video/${mediaKey.split('.').pop()}`}
            />
            Seu navegador não suporta o elemento de vídeo.
          </video>
        </div>
      </div>
    );
  }

  // Renderiza conteúdo textual
  if (content) {
    return (
      <div className={styles.contentOnly}>
        <div
          className={`${styles.contentBox} ${
            theme === 'dark' ? styles.lightBox : styles.darkBox
          }`}
        >
          <p>{content}</p>
        </div>
      </div>
    );
  }

  return null;
}

MediaContent.propTypes = {
  media: PropTypes.object.isRequired,
  zoomEffect: PropTypes.string,
  theme: PropTypes.string,
};

export default MediaContent;
