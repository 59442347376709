import React, { useEffect, useState, useCallback } from 'react';
import { DataStore } from '@aws-amplify/datastore';
import { Hub } from '@aws-amplify/core';
import { Campaign } from '../models';
import { StorageImage } from '@aws-amplify/ui-react-storage';
import { useNavigate } from 'react-router-dom';
import './Campaigns.css';

function Campaigns({ signOut }) {
  const [campaigns, setCampaigns] = useState([]);
  const navigate = useNavigate();

  // Função para buscar as campanhas usando o DataStore
  const fetchCampaigns = useCallback(async () => {
    try {
      const campaignsData = await DataStore.query(Campaign);
      // Ordenando as campanhas: ativas primeiro
      const sortedCampaigns = campaignsData.sort((a, b) => (a.isActive === true ? -1 : 1));
      setCampaigns(sortedCampaigns);
      // Redirecionar para a primeira campanha ativa, se houver
      const activeCampaign = sortedCampaigns.find((campaign) => campaign.isActive === true);
      if (activeCampaign) {
        navigate(`/media/${activeCampaign.id}`);
      }
    } catch (error) {
      console.error('Erro ao buscar campanhas:', error);
    }
  }, [navigate]);

  useEffect(() => {
    // Ouvinte para verificar se o usuário está autenticado
    const authListener = Hub.listen('auth', ({ payload: { event } }) => {
      if (event === 'signIn') {
        fetchCampaigns();
      }
    });

    
    // Inicia o DataStore após o login
    fetchCampaigns();

    // Limpa o ouvinte ao desmontar o componente
    return () => {
      authListener();
    };
  }, [fetchCampaigns]);

  const handleCardClick = (campaignId) => {
    // Simula uma transição suave ao clicar no card
    setTimeout(() => {
      navigate(`/media/${campaignId}`);
    }, 150); // Atraso de 150ms para simular o clique em tela touch
  };

  return (
    <div className="campaigns-container">
      <header className="header">
        <h1>Campanhas</h1>
        <button className="logout-button" onClick={signOut}>
          Logout
        </button>
      </header>

      <div className="campaigns-grid">
        {campaigns.length === 0 ? (
          <p className="no-campaigns">Nenhuma campanha disponível.</p>
        ) : (
          campaigns.map((campaign) => (
            <div
              key={campaign.id}
              className={`campaign-card ${campaign.isActive ? 'active' : 'inactive'}`}
              onClick={() => handleCardClick(campaign.id)}
            >
              {/* Exibe a imagem de capa da campanha */}
              <div className="campaign-image-container">
                <StorageImage
                  imgKey={campaign.mediaKey}
                  accessLevel="guest"
                  className="campaign-image"
                />
              </div>

              {/* Exibe o título da campanha */}
              <h3 className="campaign-title">{campaign.title}</h3>

              {/* Exibe o conteúdo da campanha (detalhes) */}
              <p className="campaign-content">
                {campaign.content || 'Sem detalhes disponíveis'}
              </p>

              {/* Exibe a localização (locate) e o zipCode */}
              <p className="campaign-location">
                Local: {campaign.locate || 'Não especificado'}, ZIP:{' '}
                {campaign.zipCode || 'Não especificado'}
              </p>
            </div>
          ))
        )}
      </div>
    </div>
  );
}

export default Campaigns;
