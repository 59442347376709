import React, { useEffect, useState, useRef } from 'react';
import { WiNa, WiDaySunny, WiCloud, WiRain } from 'react-icons/wi';
import { FaCircle } from 'react-icons/fa';
import styles from './PresentationPage.module.css';

function PresentationPage() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const timerRef = useRef(null);
  const [currentTime, setCurrentTime] = useState(new Date());
  const [showDate, setShowDate] = useState(true); // Alterna entre data e hora
  const [onlineStatus, setOnlineStatus] = useState(navigator.onLine);
  const [weatherData, setWeatherData] = useState(null);
  const [progress, setProgress] = useState(0); // Novo estado para o progresso do círculo

  // Array com os nomes das imagens no diretório de assets
  const imageAssets = [
    'midia001.jpg',
    'midia002.jpg',
    'midia003.jpg',
    'midia004.jpg',
    'midia005.jpg',
    'midia006.jpg',
    'midia007.jpg',
    'midia008.jpg',
    'midia009.jpg',
    'midia010.jpg',
  ];

  // Tempo de transição entre as imagens (em segundos)
  const transitionTime = 7;

  // Atualiza data e hora a cada minuto
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  // Alterna entre exibir data e hora a cada 5 segundos
  useEffect(() => {
    const interval = setInterval(() => {
      setShowDate((prevShowDate) => !prevShowDate);
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  // Checa status de conexão
  useEffect(() => {
    const handleOnline = () => setOnlineStatus(true);
    const handleOffline = () => setOnlineStatus(false);
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);
    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  // Função para buscar informações de clima com base no nome da localização (São Paulo como padrão)
  const fetchWeatherData = async (locate = 'São Paulo') => {
    try {
      const response = await fetch(
        `https://api.openweathermap.org/data/2.5/weather?q=${locate},br&appid=131c252944882a0d87ea5fa95ec07cf8&units=metric`
      );
      const data = await response.json();
      if (data && data.main) {
        setWeatherData(data);
      } else {
        setWeatherData(null);
      }
    } catch (error) {
      console.error('Erro ao buscar dados do clima:', error);
      setWeatherData(null);
    }
  };

  // Busca o clima ao montar o componente
  useEffect(() => {
    fetchWeatherData();
  }, []);

  // Inicia o ciclo de troca de imagens e controle do progresso do círculo
  useEffect(() => {
    timerRef.current = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % imageAssets.length);
    }, transitionTime * 1000);

    // Atualiza o progresso do círculo a cada 100ms
    const progressInterval = setInterval(() => {
      setProgress((prevProgress) => (prevProgress + 1) % 100);
    }, (transitionTime * 1000) / 100);

    // Limpa intervalos ao desmontar o componente
    return () => {
      clearInterval(timerRef.current);
      clearInterval(progressInterval);
    };
  }, [imageAssets.length, transitionTime]);

  return (
    <div className={styles.presentationContainer}>
      {/* Container da imagem com slides */}
      <div className={styles.imageContainer}>
        {imageAssets.map((image, index) => (
          <div
            key={index}
            className={`${styles.slide} ${
              index === currentSlide ? styles.active : ''
            }`}
          >
            <img
              className={styles.imageElement}
              src={require(`../assets/${image}`)}
              alt={`Imagem ${index + 1}`}
            />
          </div>
        ))}
      </div>

      {/* Barra de status flutuante com padding externo */}
      <div className={styles.statusBarContainer}>
        <div className={styles.statusBar}>
          <div className={styles.statusLeft}>
            {/* Caixa invisível para evitar que os itens ao lado se movam */}
            <div className={styles.dateTimeBox}>
              {showDate
                ? currentTime.toLocaleDateString()
                : currentTime.toLocaleTimeString([], {
                    hour: '2-digit',
                    minute: '2-digit',
                  })}
            </div>
            <span style={{ paddingLeft: '10px' }}>São Paulo</span>
            {weatherData ? (
              <div style={{ display: 'flex', alignItems: 'center', paddingLeft: '10px' }}>
                {weatherData.weather[0].main === 'Clear' ? (
                  <WiDaySunny size={24} className={styles.weatherIcon} />
                ) : weatherData.weather[0].main === 'Clouds' ? (
                  <WiCloud size={24} className={styles.weatherIcon} />
                ) : (
                  <WiRain size={24} className={styles.weatherIcon} />
                )}
                <span className={styles.temperature} style={{ paddingLeft: '10px' }}>
                  {Math.round(weatherData.main.temp)}°C
                </span>
              </div>
            ) : (
              <div style={{ display: 'flex', alignItems: 'center', paddingLeft: '10px' }}>
                <WiNa size={24} className={styles.weatherIcon} />
                <span className={styles.temperature} style={{ paddingLeft: '10px' }}>25°C</span>
              </div>
            )}
          </div>

          {/* Nome Clickk centralizado */}
          <div className={styles.centerText}>
            <span>Clickk</span>
          </div>

          <div className={styles.statusRight}>
            {onlineStatus ? (
              <FaCircle size={12} color="green" style={{ marginRight: '5px' }} />
            ) : (
              <FaCircle size={12} color="red" style={{ marginRight: '5px' }} />
            )}
            <span style={{ marginRight: '15px' }}>{onlineStatus ? 'Online' : 'Offline'}</span>
            <div className={styles.progressCircle}>
              <svg>
                <circle cx="18" cy="18" r="15"></circle>
                <circle
                  cx="18"
                  cy="18"
                  r="15"
                  key={currentSlide}
                  style={{
                    strokeDasharray: 94,
                    strokeDashoffset: 94 - (94 * progress) / 100,
                    transition: 'stroke-dashoffset 0.1s linear', // Transição suave a cada atualização de progresso
                  }}
                ></circle>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PresentationPage;
