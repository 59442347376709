/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "sa-east-1",
    "aws_appsync_graphqlEndpoint": "https://bojjrftlrngwlg2xumiakg75t4.appsync-api.sa-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "sa-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-qukrwnyezjcolbvnmixt7xmumq",
    "aws_cognito_identity_pool_id": "sa-east-1:1d972cc4-1c9c-4f80-8710-f66013351d83",
    "aws_cognito_region": "sa-east-1",
    "aws_user_pools_id": "sa-east-1_379hLmxDb",
    "aws_user_pools_web_client_id": "37goglr85umpofcpg049trn72a",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "clickk-storage-192ac97f6aa97-staging",
    "aws_user_files_s3_bucket_region": "sa-east-1"
};


export default awsmobile;
