import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { DataStore } from '@aws-amplify/datastore';
import { Campaign as CampaignModel } from '../models';
import VerticalMedia from './VerticalMedia';
import HorizontalMedia from './HorizontalMedia';
import './Media.module.css';

function Media() {
  const { campaignId } = useParams();
  const [campaign, setCampaign] = useState(null);
  const [loading, setLoading] = useState(true);
  const [mediaCache, setMediaCache] = useState([]);
  const navigate = useNavigate();

  // Função para pré-carregar o conteúdo
  const preloadMediaContent = async (mediaUrls) => {
    if (!mediaUrls || mediaUrls.length === 0) return;

    const cachedMedia = await Promise.all(
      mediaUrls.map(async (url) => {
        try {
          const response = await fetch(url, { cache: 'no-store' }); // Desabilitar cache do navegador
          const blob = await response.blob();
          return URL.createObjectURL(blob);
        } catch (error) {
          console.error('Erro ao pré-carregar o conteúdo de mídia:', error);
          return null;
        }
      })
    );

    setMediaCache(cachedMedia.filter((media) => media !== null));
  };

  // Busca os dados da campanha ao montar o componente
  useEffect(() => {
    const fetchCampaign = async () => {
      try {
        // Busca todas as campanhas para garantir que estamos lidando com a campanha ativa
        const allCampaigns = await DataStore.query(CampaignModel);
        const activeCampaign = allCampaigns.find(c => c.isActive);
        if (!activeCampaign || activeCampaign.id !== campaignId) {
          // Redireciona para a campanha ativa se não for a campanha correta
          navigate(`/media/${activeCampaign.id}`);
          return;
        }

        const campaignData = await DataStore.query(CampaignModel, campaignId);
        if (campaignData) {
          setCampaign(campaignData);

          // Supondo que campaignData.mediaUrls seja a lista de URLs das mídias
          const mediaUrls = campaignData.mediaUrls;
          if (mediaUrls && mediaUrls.length > 0) {
            await preloadMediaContent(mediaUrls);
          }
        } else {
          console.error('Campanha não encontrada.');
        }
      } catch (error) {
        console.error('Erro ao buscar a campanha:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchCampaign();
  }, [campaignId, navigate]);

  if (loading) {
    return <div>Carregando...</div>;
  }

  if (!campaign) {
    return <div>Campanha não encontrada.</div>;
  }

  // Extrai as configurações da campanha
  const {
    orientation = 'horizontal',
    transitionTime = '7',
    theme = 'dark',
    zoomEffect = 'none',
    slideEffect = 'none',
  } = campaign;

  // Aplica as configurações e renderiza o componente apropriado
  return (
    <div className={`media-wrapper ${theme}`}>
      {orientation === 'vertical' ? (
        <VerticalMedia
          transitionTime={parseInt(transitionTime)}
          theme={theme}
          zoomEffect={zoomEffect}
          slideEffect={slideEffect}
          mediaCache={mediaCache}
        />
      ) : (
        <HorizontalMedia
          transitionTime={parseInt(transitionTime)}
          theme={theme}
          zoomEffect={zoomEffect}
          slideEffect={slideEffect}
          mediaCache={mediaCache}
        />
      )}
    </div>
  );
}

export default Media;
