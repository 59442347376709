import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import awsExports from '../aws-exports'; // Usando as configurações do aws-exports.js
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import Campaigns from './Campaigns';
import Media from './Media';
import PresentationPage from './PresentationPage'; // Página de apresentação
import './App.css';

// Configurando o Amplify com as configurações do aws-exports.js
Amplify.configure(awsExports);

// Componente de botão para a navegação para a apresentação
const PresentationButton = () => {
  const navigate = useNavigate();
  
  return (
    <button
      style={{
        backgroundColor: '#333',
        color: 'white',
        padding: '10px',
        borderRadius: '5px',
        border: 'none',
        cursor: 'pointer',
        marginTop: '20px',
        width: '100%',
      }}
      onClick={() => navigate('/presentation')}
    >
      Ver Apresentação
    </button>
  );
};

function App() {
  return (
    <Router>
      <Routes>
        {/* Rota da página de apresentação */}
        <Route path="/presentation" element={<PresentationPage />} />
        
        {/* Rota principal com autenticação */}
        <Route
          path="/*"
          element={
            <Authenticator
              signUpAttributes={[]} // Remove o cadastro de novos usuários
              hideSignUp={true} // Bloqueia o registro de novos usuários
              components={{
                SignIn: {
                  Header() {
                    return (
                      <h1 style={{ textAlign: 'center', marginBottom: '20px', fontSize: '28px', color: '#333' }}>
                        Bem-vindo! Faça seu login
                      </h1>
                    );
                  },
                  FormFields(props) {
                    return (
                      <div
                        style={{
                          border: '2px solid #333',
                          borderRadius: '10px',
                          padding: '20px',
                          backgroundColor: 'white',
                          width: '350px',
                          margin: '0 auto',
                          boxShadow: '0 0 15px rgba(0, 0, 0, 0.1)',
                        }}
                      >
                        {props.children}
                      </div>
                    );
                  },
                  Footer() {
                    return (
                      <div style={{ textAlign: 'center' }}>
                        {/* Botão para ver a apresentação */}
                        <PresentationButton />
                      </div>
                    );
                  },
                },
              }}
            >
              {({ signOut, user }) => (
                user ? (
                  <Routes>
                    <Route path="/" element={<Campaigns user={user} signOut={signOut} />} />
                    <Route path="/media/:campaignId" element={<Media />} />
                    <Route path="*" element={<Navigate replace to="/" />} />
                  </Routes>
                ) : (
                  <main className="authenticator">
                    <h1>Login</h1>
                  </main>
                )
              )}
            </Authenticator>
          }
        />
        {/* Redireciona para a página de apresentação caso a rota não seja encontrada */}
        <Route path="*" element={<Navigate replace to="/presentation" />} />
      </Routes>
    </Router>
  );
}

export default App;
